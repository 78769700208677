import React from 'react';
import {
  TagType,
  KPIGroup as KpiGroupType,
  KPI,
  KPIValue,
} from 'app/services/api';
import { ThreatLevel, ThreatSurface } from 'app/services/apiThreatSurfaces';
import { displayHelp } from 'app/services/help';
import {
  headerStyles,
  getColWidth,
  firstColStyles,
  lastColStyles,
} from 'app/kpi/components/tables';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import { threatLevelState } from 'app/common/actions/threat-levels';
import { threatSurfaceState } from 'app/common/actions/threat-surfaces';
import { getBarColorsForValue } from 'app/common/BarGraph';
import Loader from 'app/common/Loader';

type CoverageMatrixType = {
  kpiGroups: KpiGroupType[];
  selectedTag: TagType;
  threatLevels: threatLevelState;
  threatSurfaces: threatSurfaceState;
  securityCoverageData: KPI[][];
};

const CoverageMatrix = ({
  kpiGroups,
  selectedTag,
  securityCoverageData,
  threatLevels,
  threatSurfaces,
}: CoverageMatrixType) => {
  const securityKpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === 'security-coverage'
  );
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!securityKpiGroup) {
      return;
    }

    (async () => {
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const tSurfacePayload = threatSurfaces.payload as ThreatSurface[];
  const tLevelPayload = threatLevels.payload as ThreatLevel[];

  if (!tSurfacePayload || !tLevelPayload) {
    return <div />;
  }
  const numberOfThreatSurfaces = tSurfacePayload.length;
  const allTableData = securityCoverageData?.reduce((result: any, next) => {
    const coverageValues = next.filter(
      (grp) => grp.key === 'com.pharossecurity.security-coverage.coverage'
    )[0];

    // Starting from the first threat level
    const controlTableData = tLevelPayload.map((tLevel) => {
      const rowData: any = [];
      const coverage =
        coverageValues?.kpiValue.filter(
          (kpi: any) =>
            kpi?.levelId === tLevel.id &&
            kpi?.kpi.key === 'com.pharossecurity.security-coverage.coverage'
        ) || [];

      // Add default coverage value if the coverage hasn't been set
      if (coverage.length < numberOfThreatSurfaces) {
        tSurfacePayload.forEach((surface) => {
          const hasSurface = coverage.find(
            (ele) => ele.surfaceId === surface.id
          );
          if (!hasSurface) {
            const defaultCoverage: KPIValue = {} as any;
            defaultCoverage.surfaceId = surface.id;
            defaultCoverage.value = '0';
            defaultCoverage.levelId = tLevel.id;
            coverage.push(defaultCoverage);
          }
        });
      }

      coverage.sort((a: any, b: any) => a?.surfaceId - b?.surfaceId);

      coverage?.forEach((kpi: any) => {
        const surface = tSurfacePayload.find(
          (surface) => surface.id === kpi?.surfaceId
        );
        if (surface) {
          const index = tSurfacePayload.indexOf(surface);
          rowData[index] = Math.round(Number(kpi?.value));
        }
      });

      const sum = rowData.reduce((acc: number, curr: number) => {
        return acc + curr;
      }, 0);

      const average = Math.round(sum / numberOfThreatSurfaces);

      rowData.push(average);

      // Insert threat level label to the beginning of the array
      rowData.splice(0, 0, `${tLevel.severity}. ${tLevel.name}`);

      return rowData;
    });

    return result.concat([controlTableData]);
  }, []);

  const tableAverages = allTableData.reduce(
    (result: any, controlTableData: any, tableIndex: number) => {
      const newResult = result.slice();

      controlTableData.forEach((row: any, rowIndex: number) => {
        row.forEach((col: any, colIndex: number) => {
          if (newResult[rowIndex]) {
            if (col.constructor === Number) {
              if (newResult[rowIndex][colIndex]) {
                newResult[rowIndex][colIndex] =
                  newResult[rowIndex][colIndex] + col;
              } else {
                newResult[rowIndex][colIndex] = col;
              }
              if (tableIndex === allTableData.length - 1) {
                newResult[rowIndex][colIndex] = Math.round(
                  newResult[rowIndex][colIndex] / allTableData.length
                );
              }
            }
          } else {
            newResult[rowIndex] = [];
            newResult[rowIndex][colIndex] = col;
          }
        });
      });

      return newResult;
    },
    []
  );

  const colWidth = getColWidth(numberOfThreatSurfaces);
  const helpURI = '';

  return (
    <>
      <Paper
        elevation={3}
        style={{ padding: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}
      >
        <Table size="small">
          <TableHead>
            <TableRow style={headerStyles}>
              <TableCell colSpan={numberOfThreatSurfaces + 1}>
                <Typography variant="h6">
                  {selectedTag.name} Coverage
                </Typography>
              </TableCell>
              <TableCell colSpan={1} align="right" width="60px">
                <IconButton
                  disabled={!helpURI}
                  onClick={() => displayHelp(helpURI)}
                >
                  <HelpOutlineRounded />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow key="header">
              <TableCell key="threat-preparedness" style={firstColStyles}>
                Threat Level
              </TableCell>
              {tSurfacePayload.map((surface, idx) => (
                <TableCell
                  style={{ width: colWidth }}
                  align="center"
                  key={`${surface.name}-${idx}`}
                >
                  {surface.name}
                </TableCell>
              ))}
              <TableCell style={lastColStyles} key="average" align="center">
                Row Average
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableAverages.map((row: any) => (
              <TableRow key={row[0]}>
                {row.map((col: any, idx: number) => {
                  let style = {};
                  if (idx === row.length - 1) {
                    style = {
                      backgroundColor: MuiLightBlue[900],
                      color: 'white',
                    };
                    // Center algin KPI values
                  } else if (idx > 0) {
                    let barColors = getBarColorsForValue(col);
                    style = {
                      backgroundColor: barColors.background,
                      color: barColors.textColor,
                    };
                  } else if (idx === 0) {
                    style = {
                      whiteSpace: 'nowrap',
                    };
                  }

                  return (
                    <TableCell
                      key={`${row[0]}-${idx}`}
                      style={style}
                      component="th"
                      scope="row"
                      align={idx > 0 ? 'center' : 'left'}
                    >
                      {col}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default CoverageMatrix;
